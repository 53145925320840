import { Avatar, Button, Card, Col, Divider, Input, message, Modal, Row, Typography } from 'antd'
import React, { useState } from 'react'
import styled from 'styled-components'
import {
    BsChevronDoubleRight
} from 'react-icons/bs'
import { useMediaQuery } from 'react-responsive'
import { AuthStore } from '../store/auth'
import dayjs from 'moment'
import theme from '../theme'

import {
    GrNotification,
    GrCopy,
} from 'react-icons/gr'
import api from '../api'

const Content = styled(Card)`
    margin-top:1rem;
    box-shadow: 0 4px 20px 1px rgba(0,0,0,.06), 0 1px 4px rgba(0,0,0,.08);
    border: none;
    border-radius:10px;
`


function ProfileScreen() {
    const [visible] = useState(false)

    const isMobile = useMediaQuery({ query: '(max-width: 992px)' })

    const authContext = AuthStore()

    const [ott] = useState({
        code: '',
        expired: null,
        loading: true,
        line_url: ''
    })

    const openModal = () => {
        // setOtt({
        //     code: '',
        //     expired: null,
        //     loading: true,
        //     line_url: ''
        // })
        // setVisible(true)

        api.users.ott().then(result => {
            // console.log(result.data.line_url);
            // setOtt({ ...result.data, loading: false })
            window.open(result.data.line_url, '_blank')
        })
    }

    return (
        <Row>
            <Col xs={24}>
                <Content style={{ padding: isMobile ? '0rem' : '1rem' }}>
                    <Row>
                        <Col xs={24} lg={6} style={{ textAlign: '-webkit-center', color: '#47404f' }}>
                            <Row>
                                <Col xs={24}>
                                    <Avatar
                                        style={{
                                            backgroundImage: 'url("/images/avatars.jpg")',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            objectFit: 'contain',
                                            height: '250px',
                                            width: '250px',
                                            borderRadius: '50%',
                                            margin: '1rem',
                                            boxShadow: '0 1px 15px 1px rgba(0,0,0,.24), 0 1px 6px rgba(0,0,0,.04)'
                                        }}
                                        shape='circle'
                                        src={authContext?.state?.user?.fullpath_img}
                                    />
                                </Col>
                            </Row>
                            <Divider />
                            <Row style={{ textAlign: 'center', padding: '1rem' }}>
                                <Col xs={24}>
                                    <Typography style={{ fontSize: '1.5rem' }}>{authContext.state.user.fullname_en || authContext.state.user.fullname_th}</Typography>
                                    <Typography style={{ fontSize: '1rem', color: '#70657b' }}>{authContext.state.user.fullname_th || '-'}</Typography>
                                    <Row>
                                        <Col xs={24}>
                                            <Badge>{authContext.state.user.role_text}</Badge>
                                            <Badge>{authContext.state.user.level_text}</Badge>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} lg={18} style={{ padding: isMobile ? '0rem' : '1rem 0rem 0rem 6rem' }}>
                            <Row style={{ textAlign: 'left', padding: '1rem 0rem 0rem 0rem' }}>
                                <Col xs={6}>
                                    <Typography style={{ fontWeight: '600' }}>Gender</Typography>
                                </Col>
                                <Col xs={18}>
                                    <Typography>{authContext.state.user.gender_text || '-'}</Typography>
                                </Col>
                            </Row>
                            <Row style={{ textAlign: 'left', padding: '1rem 0rem 0rem 0rem' }}>
                                <Col xs={6}>
                                    <Typography style={{ fontWeight: '600' }}>Date of birth</Typography>
                                </Col>
                                <Col xs={18}>
                                    <Typography>{authContext.state.user.dob ? dayjs(authContext.state.user.dob).format('MMMM D, YY') : '-'}</Typography>
                                </Col>
                            </Row>
                            <Row style={{ textAlign: 'left', padding: '1rem 0rem 0rem 0rem' }}>
                                <Col xs={6}>
                                    <Typography style={{ fontWeight: '600' }}>E-mail</Typography>
                                </Col>
                                <Col xs={18}>
                                    <Typography>{authContext.state.user.email || '-'}</Typography>
                                </Col>
                            </Row>
                            <Row style={{ textAlign: 'left', padding: '1rem 0rem 0rem 0rem' }}>
                                <Col xs={6}>
                                    <Typography style={{ fontWeight: '600' }}>Telephone</Typography>
                                </Col>
                                <Col xs={18}>
                                    <Typography>{authContext.state.user.tel || '-'}</Typography>
                                </Col>
                            </Row>
                            <Row style={{ textAlign: 'left', padding: '1rem 0rem 0rem 0rem' }}>
                                <Col xs={6}>
                                    <Typography style={{ fontWeight: '600' }}>Line</Typography>
                                </Col>
                                <Col xs={18}>
                                    <Typography>{authContext.state.user.line_id || '-'}</Typography>
                                </Col>
                            </Row>
                            <Row style={{ textAlign: 'left', padding: '1rem 0rem 0rem 0rem' }}>
                                <Col xs={6}>
                                    <Typography style={{ fontWeight: '600' }}>Prefer Contact</Typography>
                                </Col>
                                <Col xs={18}>
                                    <Typography>{authContext.state.user.prefer_contact || '-'}</Typography>
                                </Col>
                            </Row>
                            <Row style={{ textAlign: 'left', padding: '1rem 0rem 0rem 0rem' }}>
                                <Col xs={6}>
                                    <Typography style={{ fontWeight: '600' }}>Occupation</Typography>
                                </Col>
                                <Col xs={18}>
                                    <Typography>{authContext.state.user.occupation || '-'}</Typography>
                                </Col>
                            </Row>
                            <Row style={{ textAlign: 'left', padding: '1rem 0rem 0rem 0rem' }}>
                                <Col xs={6}>
                                    <Typography style={{ fontWeight: '600' }}>Address</Typography>
                                </Col>
                                <Col xs={18}>
                                    <Typography>{authContext.state.user.address || '-'}</Typography>
                                </Col>
                            </Row>
                            <Row style={{ textAlign: 'left', padding: '1rem 0rem 0rem 0rem' }}>
                                <Col xs={6}>
                                    <Typography style={{ fontWeight: '600' }}>Session Received</Typography>
                                </Col>
                                <Col xs={18}>
                                    <Typography>-</Typography>
                                </Col>
                            </Row>
                            <Row style={{ textAlign: 'left', padding: '1rem 0rem 0rem 0rem' }}>
                                <Col xs={6}>
                                    <Typography style={{ fontWeight: '600' }}>Professional</Typography>
                                </Col>
                                {authContext.state.user.userpros && authContext.state.user.userpros.length > 0 ? (
                                    <Col xs={24} style={{ marginTop: '1rem' }}>
                                        {authContext.state.user.userpros.map((up, key) => (
                                            <Typography
                                                key={key}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    fontSize: '.8rem'
                                                }}
                                            >
                                                <BsChevronDoubleRight color={theme.color.success} style={{ marginRight: '0.5rem', marginTop: '0.25rem', marginBottom: '0.25rem' }} />
                                                {up.professional.title}
                                            </Typography>
                                        ))}
                                    </Col>
                                ) : (
                                        <Col xs={18}>
                                            <Typography>
                                                -
                                                </Typography>
                                        </Col>
                                    )}
                            </Row>
                            <Row style={{ textAlign: 'left', padding: '1rem 0rem 0rem 0rem' }}>
                                <Col xs={24}>
                                    {authContext?.state?.user?.line_user_id ? (
                                        <LineConnected>
                                            <GrNotification />
                                                        Line notify connected
                                        </LineConnected>
                                    ) : (
                                            <LineButton htmlType='button' onClick={() => { openModal() }}>
                                                Connect
                                                <GrNotification />
                                                    Line notify
                                            </LineButton>
                                        )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Content>
            </Col>
            <Modal
                title="Connect to Line notify"
                visible={visible}
                okText='Open Line'
                cancelText='Close'
                onOk={() => { window.open(ott.line_url, '_blank') }}
                onCancel={() => { window.location.reload() }}
                width={'800px'}
                style={{ top: '10rem' }}
                okButtonProps={{
                    disabled: ott.loading
                }}
            >
                <Row>
                    <Col xs={24}>
                        <div style={{ display: 'grid', gridTemplateColumns: '100%' }}>
                            <label>Copy Code and send message to Line.</label>
                            <div style={{ display: 'flex' }}>
                                <Input
                                    readOnly
                                    id='user_token'
                                    value={ott.code}
                                />
                                <Button loading={ott.loading} htmlType='button' icon={<GrCopy />} onClick={() => {
                                    if (ott.expired && ott.expired * 1000 > Number(new Date())) {
                                        const user_token = document.getElementById('user_token')
                                        user_token.select()
                                        user_token.setSelectionRange(0, 99999)

                                        document.execCommand("copy")

                                        message.info({
                                            content: 'copied',
                                            duration: 1,
                                        })
                                    }
                                }}>

                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Modal>
        </Row>
    )
}

const Badge = styled.div`
    background: unset;
    border: 1px solid #263db5;
    color: #263db5;
    font-weight: 500;
    padding: .5rem!important;
    margin: .25rem!important;
    border-radius: 10rem;
    display: inline-block;
    font-size: 0.75rem;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    box-sizing: border-box;
    font-family: inherit;
`

const LineButton = styled(Button)`
    background-color:#07b53b;
    color:#FFFFFF;
    border:1px solid #07b53b !important;
    font-weight:bold;
    width:100%;

    svg{
        margin: 0px 0.5rem;
        path{
            stroke:white;
            stroke-width:3;
        }
    }

    &:hover,&:active,&:focus{
        background-color:#FFFFFF;
        color:#07b53b;

        svg{
            path{
                stroke:#07b53b;
            }
        }
    }
`

const LineConnected = styled(Typography)`
    color:#07b53b;
    font-weight:bold;
    svg{
        margin: 0px 0.5rem;
        path{
            stroke:#07b53b;
            stroke-width:3;
        }
    }
`

export default ProfileScreen
